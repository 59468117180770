import { async } from "@firebase/util";
import React, { useState } from "react";
import { Operations } from "../Api/Operations";
import Button from "../Components/Button";
import TimeInput from "../Components/TimeInput";
import DateInput from "../Components/DateInput";
import Options from "../Components/Options";
import TextInput from "../Components/TextInput";
import { types, typesTitles } from "../Utils/Globals";
import Loader from "../Components/Loader";
const _customer = {
    id: "",
    docter: "",
    customerName: "",
    type: "",
    opration: "",
    oprationDetails: "",
    amount: "",
    phoneNumber: "",
    dateCreated: new Date().toISOString().slice(0, 10) + " " + new Date().toISOString().slice(11, 16),
};

const AddOperation = ({ customer = _customer }) => {
    const [docter, setDocter] = useState(customer.docter);
    const [customerName, setCustomerName] = useState(customer.customerName);
    const [type, setType] = useState(customer.type);
    const [opration, setOpration] = useState(customer.opration);
    const [oprationDetails, setOprationDetails] = useState(customer.oprationDetails);
    const [amount, setAmount] = useState(customer.amount);
    const [phoneNumber, setPhoneNumber] = useState(customer.phoneNumber);
    const [dateCreated, setDateCreated] = useState(customer.dateCreated);
    const [loading, setLoading] = useState(false);

    console.log({ Date: Date.now().toFixed(0) });
    console.log({ dateCreated });
    const send = async () => {
        const data = { docter, customerName, type, opration, oprationDetails, amount, phoneNumber, dateCreated };
        console.log(data);
        setLoading(true);
        await Operations.SendOperation(data);
        window.location.reload();
    };

    const timestamp = new Date().getTime();
    console.log(timestamp); // 👉️ 1642664853302

    const date = new Date(timestamp);
    console.log(date); // 👉️ Thu Jan 20 2022 09:48:00

    console.log(date.toString()); // 👉️ "Thu Jan 20 2022 09:48:00"

    // 👇️ Format date and time using different locales
    console.log(date.toLocaleString("en-US")); // 👉️ "1/20/2022, 9:50:15 AM"
    console.log(date.toLocaleString("en-GB")); // 👉️ "20/01/2022 09:50:15"
    console.log(date.toLocaleString("sv")); // 👉️ "2022-01-20 09:50:15"

    // 👇️ Display only date
    console.log(date.toLocaleDateString("en-US")); // 👉️ "1/20/2022"

    // 👇️ Display only time
    console.log(date.toLocaleTimeString("en-US")); //

    return loading ? (
        <Loader />
    ) : (
        <div className="carbonic-card p-5 my-auto m-5" style={{ borderRadius: 15 }}>
            <TextInput placeholder="الدكتور" value={docter} setValue={setDocter} />
            <TextInput placeholder="الاسم" value={customerName} setValue={setCustomerName} />
            <TextInput placeholder="العملية" value={opration} setValue={setOpration} />
            <TextInput placeholder="تفاصيل العملية" value={oprationDetails} setValue={setOprationDetails} />
            <TextInput placeholder="المبلغ" value={amount} setValue={setAmount} />
            <TextInput placeholder="رقم الهاتف" value={phoneNumber} setValue={setPhoneNumber} />
            <Options
                options={types}
                onChange={(option) => {
                    setType(option.value);
                }}
            />
            <DateInput
                value={dateCreated.split(" ")[0]}
                onChange={(val) => {
                    setDateCreated(val + " " + dateCreated.split(" ")[1]);
                    console.log({ val });
                }}
                title="التاريخ"
            />
            <TimeInput
                title="الوقت"
                value={dateCreated.split(" ")[1]}
                onChange={(val) => {
                    setDateCreated(dateCreated.split(" ")[0] + " " + val);
                }}
            />
            <div style={{ marginTop: 40 }}>
                <Button onClick={send} title="حفظ" />
            </div>
        </div>
    );
};

export default AddOperation;
