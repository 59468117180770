import React, { useState } from "react";

const Options = ({ options, onChange }) => {
    const [selected, setSelected] = useState(options[0]);

    return (
        <div className="options-container font-bold ">
            {options.map((option) => (
                <span
                    key={option.value}
                    className={selected === option ? "active" : ""}
                    onClick={() => {
                        setSelected(option);
                        onChange && onChange(option);
                    }}>
                    {option.title}
                </span>
            ))}
        </div>
    );
};

export default Options;
