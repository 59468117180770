// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = true
    ? {
          apiKey: "AIzaSyDvGxCiYIZ8BCsv_fOuCoN38v6xfoZ8hfE",
          authDomain: "auada-b481c.firebaseapp.com",
          databaseURL: "https://auada-b481c-default-rtdb.firebaseio.com",
          projectId: "auada-b481c",
          storageBucket: "auada-b481c.appspot.com",
          messagingSenderId: "335949596034",
          appId: "1:335949596034:web:216911541f469b1b2bbb9b",
          measurementId: "G-PPVGM77Q9Z",
      }
    : {
          apiKey: "AIzaSyBcMJaJaGINgrUpzX6s7zGJjgQ2qG13haY",
          authDomain: "marketcaptain-27954.firebaseapp.com",
          // databaseURL: "https://marketcaptain-27954-default-rtdb.firebaseio.com",
          projectId: "marketcaptain-27954",
          storageBucket: "marketcaptain-27954.appspot.com",
          messagingSenderId: "178667468654",
          appId: "1:178667468654:web:2da5289cb09a98ce6cf37f",
          measurementId: "G-QLR7L956CC",
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getDatabase();
export const fdb = getFirestore();
