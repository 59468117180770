import { db } from "../firebase";
import { where } from "firebase/firestore";
import { get, ref } from "firebase/database";
import GetArray, { GetObject } from "../Utils/GetLoaclData";
import Formatter from "../Utils/Formatter";
import FirebaseControllers from "../Utils/FirebaseControllers";

const Items = {
    AddItem: async (items) => {
        let data = {
            id: JSON.parse(localStorage.getItem("user")).resever,
            items,
        };
        await FirebaseControllers.SetObject("userItems", data);
    },
    GetItems: async (categoryId, root) => await StupItemsData(categoryId, root),
};

export default Items;

export const GitBills = () => {
    return GetArray("localItems").filter((o) => o.quantity > 0);
};

export const GetCategories = async (favorites) => await StupCategoriesData(favorites);

export const GetFavoriteItems = (categoryId) => {
    let _items = GetArray("localItems");
    return categoryId ? _items.filter((o) => o.favorite && o.categoryId === categoryId) : _items.filter((o) => o.favorite);
};

const StupItemsData = async (categoryId, root = "items") => {
    let items = [];
    if (root === "userItems") {
        let data = await FirebaseControllers.GetData(root, categoryId);
        data.items && (items = data.items);
    } else {
        let Query = categoryId ? where("categoryId", "==", categoryId) : false;
        items = await FirebaseControllers.GetDataQuery(root, Query);
    }
    let localItems = GetArray("localItems").filter((o) => o.categoryId === categoryId);
    return UpdateQuantityAndFavorite();

    function UpdateQuantityAndFavorite() {
        localItems.forEach((local_Item) => {
            items.forEach((item) => {
                if (item.id === local_Item.id) {
                    item.favorite = local_Item.favorite;
                    item.quantity = local_Item.quantity;
                }
            });
        });
        items.forEach((element) => {
            element.currencyPrice = Formatter(element.price, 1);
        });
        return items;
    }
};

const StupCategoriesData = async (favorites) => {
    // let operation = (await get(ref(db, "/operations"))).val() || [];
    // console.log({ operation });
    let CATEGORIES = (await get(ref(db, "/categories"))).val() || [];
    console.log("StupCategoriesData");
    let localCategories = GetObject("localCategories");
    let cateArr = [];
    let _categories = CATEGORIES;
    if (favorites) {
        _categories = [];
        let _items = GetFavoriteItems();
        CATEGORIES.forEach((category) => {
            let founded = _items.find((o) => o.categoryId === category.id);
            if (founded) _categories.push(category);
        });
    }

    _categories.forEach((cate) => {
        let localCate = localCategories[cate.id];
        if (localCate) {
            cate.quantity = localCate.quantity;
            cate.totalAmount = localCate.totalAmount;
        }
        cateArr.push(cate);
    });
    return cateArr;
};
