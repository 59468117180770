import Scroller from "../Containers/Scroller";
import React, { useEffect, useState } from "react";
import useLocalStorage from "@rehooks/local-storage";
import { Operations } from "../Api/Operations";
import AddOperation from "./AddOperation";
import { types, typesTitles } from "../Utils/Globals";
import PopupContainer from "../Containers/PopupContainer";
import AddOperationButton from "../Components/AddOperationButton";
import Formatter from "../Utils/Formatter";
import TableOrGirdContainer from "../Containers/TableOrGirdContainer";
import getResizebleTableObj from "../Utils/ResizebleMethods";
import Boolean from "../Components/Boolean";

const Label = ({ label, title }) => (
    <p>
        <span className="px-2 carbonic-3 font-light">{label} :</span> <span className="carbonic">{title}</span>
    </p>
);

const ItemBuilder = ({ item, Owl }) => (
    <div key={item.id} className="categories-card ">
        <Label label="الدكتور" title={item.docter} />
        <Label label="المراجع" title={item.customerName} />
        <Label label="النوع" title={typesTitles[item.type]} />
        <Label label="العملية" title={item.opration} />
        <Label label="تفاصيل العملية" title={item.oprationDetails} />
        <Label label="المبلغ" title={Formatter(item.amount)} />
        <Label label="رقم الهاتف" title={item.phoneNumber} />
        <Label label="تاريخ الانشاء" title={item.dateCreated} />
    </div>
);

const tableCols = [
    { title: "الدكتور", key: "docter", initialWidthFactor: 0.1 },
    { title: "المراجع", key: "customerName", initialWidthFactor: 0.1 },
    { title: "النوع", key: "type", initialWidthFactor: 0.1 },
    { title: "العملية", key: "opration", initialWidthFactor: 0.1 },
    { title: "تفاصيل العملية", key: "oprationDetails", initialWidthFactor: 0.3 },
    { title: "المبلغ", key: "amount", initialWidthFactor: 0.1 },
    { title: "رقم الهاتف", key: "phoneNumber", initialWidthFactor: 0.1 },
    { title: "تاريخ الانشاء", key: "dateCreated", initialWidthFactor: 0.1 },
];

const _spliter = {
    cols: tableCols,
    containerClass: "split-container",
    storageKey: "split-colums",
    widthToDisplay: 2000,
    className: "owl-table",
    showIndex: true,
    isTable: true,
    minColWidth: 0.1,
    setTable: () => {},
    table: localStorage.getItem("owl-table") !== "false",
};
const Owl = {
    spliter: _spliter,
    items: [],
    setItems: () => {},
    ResizebleObj: {},
};
const CategoriesView = () => {
    console.log(Owl.spliter.table);
    const [operations, setOperations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [toggleAdd, setToggleAdd] = useState(false);

    const fetchData = async () => {
        try {
            getResizebleTableObj(Owl.ResizebleObj, Owl.spliter);
            let _categories = await Operations.GetOperation();
            setLoading(false);
            console.log(Owl);
            Owl.setItems(_categories);
            if (!Owl.spliter.className) Owl.spliter.className = "owl-table";
            // setOperations(_categories);
        } catch (error) {
            console.log({ error });
        }
    };
    React.useMemo(() => {
        if (Owl.init) return;
        Owl.init = true;
        fetchData();
    }, []);

    const customer = {
        id: "1",
        docter: "سمير",
        customerName: "رضا احمد",
        type: 1,
        opration: "قلع",
        oprationDetails: "تم قلع السن",
        amount: 25000,
        phoneNumber: "0790000000",
        dateCreated: new Date().toISOString().slice(0, 10) + " " + new Date().toISOString().slice(11, 16),
    };

    return (
        <>
            <Boolean
                title={"سجل"}
                value={Owl.spliter.table}
                storageKey={"owl-table"}
                onChange={(value) => {
                    Owl.spliter.setTable(value);
                }}
            />
            <Scroller loading={loading} className="larg-grid">
                <TableOrGirdContainer Owl={Owl} ItemBuilder={ItemBuilder} />
                {toggleAdd ? (
                    <PopupContainer close={() => setToggleAdd(false)}>
                        <AddOperation
                        //customer={customer}
                        />
                    </PopupContainer>
                ) : (
                    <AddOperationButton
                        onClick={() => {
                            setToggleAdd(true);
                        }}
                    />
                )}
            </Scroller>
        </>
    );
};

export default CategoriesView;
