import React, { useState, useEffect } from "react";
import ItemCard from "./Components/ItemCard";
import { useParams } from "react-router-dom";
import { GetFavoriteItems } from "../Api/Items";
import Formatter, { Round } from "../Utils/Formatter";
import DetailsLabel from "./Components/DetailsLabel";
import GetArray, { GetObject } from "../Utils/GetLoaclData";
import Scroller from "../Containers/Scroller";
import ItemCardThemeTwo from "./Components/ItemCardThemeTwo";
import PopupInput from "../Components/PopupInput";

let selected = { quantity: 0, totalAmount: 0 };

let localItems = GetArray("localItems");
let localCategories = GetObject("localCategories");

const FavortiesItemsView = () => {
    const [updateUi, setUpdateUi] = useState(false);
    const [items, setItems] = useState([]);
    const [isThemeTow, setIsThemeTow] = useState(localStorage.getItem("isThemeTow") === "1");
    const [itemToUpdate, setItemToUpdate] = useState("");

    let categoryId = useParams().categoryId;

    const getFiletered = () =>
        categoryId
            ? localItems.filter((o) => o.favorite && o.quantity > 0 && o.categoryId === categoryId)
            : localItems.filter((o) => o.favorite && o.quantity > 0);

    const setSelecteds = () => {
        let filtered = getFiletered();
        selected = {
            categoryId,
            quantity: filtered.length,
            totalAmount: Formatter(
                filtered.reduce((acc, cur) => acc + cur.price * cur.quantity, 0),
                1
            ),
        };
    };

    useEffect(() => {
        setSelecteds();
        let _items = GetFavoriteItems(categoryId);
        setItems(_items);
    }, []);
    const updateItemInfos = (item, type) => {
        if (type === "popup") {
            setItemToUpdate(item);
            return;
        }
        localItems = localItems.filter((o) => o.id !== item.id);
        (item.quantity > 0 || item.favorite) && localItems.push(item);
        localStorage.setItem("localItems", JSON.stringify(localItems));

        setSelecteds();

        localCategories[categoryId] = selected;
        localStorage.setItem("localCategories", JSON.stringify(localCategories));
        setUpdateUi(!updateUi);
    };

    // return (
    //     <>

    //         <ListView items={items} renderItems={(item) => <ItemCard key={item.id} item={item} update={updateItemInfos} />} />;
    //         <DetailsLabel label={selected} />
    //     </>
    const popupUpdateItem = (newVal) => {
        items.find((o) => o.id === itemToUpdate.id).quantity = Round(newVal);
        updateItemInfos(itemToUpdate);
        setItemToUpdate("");
    };
    return (
        <>
            <img onClick={ChangeTamplate} alt="web-design.png" src="/web-design.png" className="absolute z-4" style={{ height: 15 }} />
            {isThemeTow ? (
                <>
                    <Scroller className="Grid">
                        {items.map((item, i) => (
                            <ItemCardThemeTwo key={i} item={item} update={updateItemInfos} />
                        ))}
                    </Scroller>
                </>
            ) : (
                <>
                    <Scroller className="larg-grid">
                        {items.map((item, i) => (
                            <ItemCard key={i} item={item} update={updateItemInfos} />
                        ))}
                    </Scroller>
                </>
            )}
            <DetailsLabel label={selected} />
            {itemToUpdate && <PopupInput onSubmit={popupUpdateItem} close={() => setItemToUpdate("")} />}
        </>
    );

    function ChangeTamplate() {
        localStorage.setItem("isThemeTow", isThemeTow ? "0" : "1");
        setIsThemeTow(!isThemeTow);
    }
};

export default FavortiesItemsView;
