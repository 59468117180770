import React from "react";

const PopupContainer = ({ close, children, className = "p-4" }) => {
    return (
        <div
            id="13"
            onClick={({ target }) => target.id === "13" && close()}
            style={{ backgroundColor: "rgba(0,0,0,0.7)", zIndex: 10000 }}
            className={`fixed row inset-0 scroller fad-opacity overflow-y-auto py-10 ${className}`}>
            <div className="flex-grow max-w-md m-auto fad-in ">{children}</div>
        </div>
    );
};

export default PopupContainer;
