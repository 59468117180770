import React, { useState, useEffect } from "react";
import ItemCard from "./Components/ItemCard";
import { useParams } from "react-router-dom";
import Formatter, { Round } from "../Utils/Formatter";
import DetailsLabel from "./Components/DetailsLabel";
import GetArray, { GetObject } from "../Utils/GetLoaclData";
import Scroller from "../Containers/Scroller";
import Items from "../Api/Items";
import AddItemsCard from "./Components/AddItemsCard";
import Language from "../Utils/Languages";
import ItemCardThemeTwo from "./Components/ItemCardThemeTwo";
import PopupInput from "../Components/PopupInput";

let selected = { quantity: 0, totalAmount: 0 };

let localItems = GetArray("localItems");
let localCategories = GetObject("localCategories");

const ItemsView = () => {
    const [items, setItems] = useState([]);
    const [, setRefrishUi] = useState(0);
    const [loading, setloading] = useState(true);
    const [popupAddItem, setPopupAddItem] = useState(false);
    const resever = JSON.parse(localStorage.getItem("user")).resever;
    const [isThemeTow, setIsThemeTow] = useState(localStorage.getItem("isThemeTow") === "1");
    const [itemToUpdate, setItemToUpdate] = useState("");
    let categoryId = useParams().categoryId;

    const getFiletered = () =>
        categoryId ? localItems.filter((o) => o.quantity > 0 && o.categoryId === categoryId) : localItems.filter((o) => o.quantity > 0);

    const setSelecteds = () => {
        let filtered = getFiletered();
        selected = {
            categoryId: categoryId,
            quantity: filtered.length,
            totalAmount: Formatter(
                filtered.reduce((acc, cur) => acc + cur.price * cur.quantity, 0),
                1
            ),
        };
    };
    const fetchItems = async () => {
        setSelecteds();
        let root = resever === categoryId ? "userItems" : "items";
        let _items = await Items.GetItems(categoryId, root);
        setloading(false);
        console.log(_items);
        setItems(_items);
    };
    useEffect(() => {
        fetchItems();
    }, []);

    const updateItemInfos = (item, type) => {
        if (type === "popup") {
            setItemToUpdate(item);
        } else {
            localItems = localItems.filter((o) => o.id !== item.id);
            (item.quantity > 0 || item.favorite) && localItems.push(item);
            localStorage.setItem("localItems", JSON.stringify(localItems));
            setSelecteds();
            localCategories[categoryId] = selected;
            localStorage.setItem("localCategories", JSON.stringify(localCategories));
            setRefrishUi(Math.random());
        }
    };

    const popupUpdateItem = (newVal) => {
        newVal = Round(newVal);
        items.find((o) => o.id === itemToUpdate.id).quantity = newVal;
        updateItemInfos(itemToUpdate);
        setItemToUpdate("");
    };

    return (
        <>
            <img onClick={ChangeTamplate} alt="web-design.pngs" src="/web-design.png" className="absolute z-4" style={{ height: 15 }} />

            {isThemeTow ? (
                <Scroller className="Grid" loading={loading}>
                    {resever === categoryId && (
                        <p onClick={() => setPopupAddItem(true)} className="font-bold p-3 rounded-2 green-card text-xl text-center white-text col-span-full">
                            {Language.AddItem}
                        </p>
                    )}
                    {items.map((item, i) => (
                        <ItemCardThemeTwo key={i} item={item} update={updateItemInfos} />
                    ))}
                    {popupAddItem && <AddItemsCard myItems={items} setMyItems={setItems} onComplate={() => setPopupAddItem(false)} />}
                </Scroller>
            ) : (
                <Scroller className="larg-grid" loading={loading}>
                    {resever === categoryId && (
                        <p onClick={() => setPopupAddItem(true)} className="font-bold p-3 rounded-2 green-card text-xl text-center white-text col-span-full">
                            {Language.AddItem}
                        </p>
                    )}
                    {items.map((item, i) => (
                        <ItemCard key={i} item={item} update={updateItemInfos} />
                    ))}
                    {popupAddItem && <AddItemsCard myItems={items} setMyItems={setItems} onComplate={() => setPopupAddItem(false)} />}
                </Scroller>
            )}
            {itemToUpdate && <PopupInput onSubmit={popupUpdateItem} close={() => setItemToUpdate("")} />}
            <DetailsLabel label={selected} />
        </>
    );

    function ChangeTamplate() {
        localStorage.setItem("isThemeTow", isThemeTow ? "0" : "1");
        setIsThemeTow(!isThemeTow);
    }
};

export default ItemsView;
