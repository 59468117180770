import { collection, doc, getDoc, getDocs, limit, query, setDoc } from "firebase/firestore";
import { uid } from "uid";
import { fdb } from "../firebase";
const FirebaseControllers = {
    GetData: async (_doc, id) => {
        const docRef = doc(fdb, _doc, id);
        const docSnap = await getDoc(docRef);
        console.log({ docSnap });
        return docSnap.data() || [];
        // if (docSnap.exists()) {
        //     console.log("Document data:", docSnap.data());
        // } else {
        //     // doc.data() will be undefined in this case
        //     console.log("No such document!");
        //     return [];
        // }
    },

    SetData: async (_doc, data, GenrateId) => {
        if (GenrateId)
            data.forEach((d) => {
                d.id = uid();
                const citiesRef = collection(fdb, _doc);
                setDoc(doc(citiesRef, d.id), d);
            });
        else
            data.forEach((d) => {
                const citiesRef = collection(fdb, _doc);
                setDoc(doc(citiesRef, d.id), d);
            });
    },
    SetObject: async (_doc, data, GenrateId) => {
        if (GenrateId || !data.id) data.id = uid();
        const citiesRef = collection(fdb, _doc);
        await setDoc(doc(citiesRef, data.id), data);
    },

    GetDataQuery: async (_doc, Query) => {
        const q = Query
            ? query(collection(fdb, _doc), limit(250), Query)
            : //
              query(collection(fdb, _doc), limit(250));
        // , where("name", "==", "other")
        let arr = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        return arr;
    },
};

export default FirebaseControllers;
