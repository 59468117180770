const TheItem = "المادة";
// const Item = "مادة";
const Language = {
    ItemName: " اسم " + TheItem,
    ItemPrice: " سعر " + TheItem,
    ItemImage: "اضغط لاضافة صورة " + TheItem,
    Save: "حفظ",
    Confirm: "تاكيد",
    Cancel: "الغاء",
    AddItem: "إضافة مادة",
    Quantity: "العدد",
};
export const DefaultImage = "https://d1nhio0ox7pgb.cloudfront.net/_img/o_collection_png/green_dark_grey/512x512/plain/object_cube.png";
// "https://d1nhio0ox7pgb.cloudfront.net/_img/o_collection_png/green_dark_grey/512x512/plain/shopping_cart.png";
//"https://www.clipartmax.com/png/middle/173-1730336_food-items-food-products-icon-png.png";
export default Language;
