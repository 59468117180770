import FirebaseControllers from "../Utils/FirebaseControllers";

export const Operations = {
    SendOperation: async (bill) => {
        await FirebaseControllers.SetObject("operations", bill);
    },
    GetOperation: async () => {
        // let billId = (JSON.parse(localStorage.getItem("user")) || { phoneNumber: "" }).phoneNumber;
        return await FirebaseControllers.GetDataQuery("operations");
    },
};
