import React from "react";

const Loader = () => {
    return (
        <div className="lds-ripple col-span-full" style={{ columnSpan: "all" }}>
            <div></div>
            <div></div>
        </div>
    );
};

export default Loader;
