import axios from "axios";

const ApiRoot = "https://imgbb.com/json";
const getAxiosInstance = (root) => {
    let instance = axios.create({
        baseURL: root,
        headers: {
            "Content-Type": "application/json",
        },
    });
    // instance.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    return instance;
};

export const API = getAxiosInstance(ApiRoot);
