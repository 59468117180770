import React, { useEffect, useState } from "react";

const InstallPWA = (props) => {
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener("beforeinstallprompt", handler);

        return () => window.removeEventListener("transitionend", handler);
    }, []);

    const onClick = (evt) => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
    };
    if (!supportsPWA) {
        return null;
    }
    return (
        <div className={`flex font-bold flex-row py-2 carbonic-card carbonic justify-center fixed bottom-0 z-50 left-0 p-10 m-5 ${props.className}`}>
            <p onClick={onClick}>تثبيت التطبيق</p>
        </div>
    );
};

export default InstallPWA;
