import React from "react";
const DateInput = ({ title, value, onChange }) => {
    const onSelect = ({ target }) => {
        onChange(target.value);
    };
    return (
        <div className="owl-label-conatiner carbonic-card-2">
            <input type="date" className="owl-date carbonic-card-2 carbonic" defaultValue={new Date().toISOString().slice(0, 10)} onChange={onSelect} />
            <p className="owl-label">{title} </p>
        </div>
    );
};
export default DateInput;
