import React from "react";
const getClass = (value) => "owl-toggleed-bg" + (value ? " owl-toggleed" : "");
const Boolean = ({ className, value, storageKey, title, onChange }) => {
    console.debug("Boolean RD");

    return (
        <div className={`owl-toggle-button ${className}`}>
            <p
                onClick={(e) => {
                    let target = e.currentTarget;
                    value = !value;
                    target.className = getClass(value);
                    if (storageKey) localStorage.setItem(storageKey, value.toString());
                    onChange(value);
                }}
                value={value}
                className={getClass(value)}>
                <span />
            </p>
            <p className="font-bold owl-text-xs carbonic ">{title}</p>
        </div>
    );
};

export default Boolean;
