import React from "react";

const ListLoader = () => (
    <div style={{ zIndex: 8000 }} className=" py-16 flex place-items-center place-content-center col-span-full">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-50 -50 500 500" width={100}>
            <path
                className="squiggle"
                fill="none"
                stroke="#DA3746"
                strokeWidth="46"
                d="M137,372.1l0-30.3h191.8c33.8,0,61.2-27.4,61.2-61.2V88.8c0-33.9-27.5-61.4-61.4-61.4H87.3c-33.8,0-61.1,27.4-61.1,61.1l0.6,238.7c0.1,34.6,28.3,62.5,63.1,62.5H160"
            />
        </svg>
    </div>
);

export default ListLoader;
